// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-quickbooks-integration-js": () => import("./../src/pages/features/quickbooks-integration.js" /* webpackChunkName: "component---src-pages-features-quickbooks-integration-js" */),
  "component---src-pages-for-growers-shippers-internet-of-things-js": () => import("./../src/pages/for-growers-shippers/internet-of-things.js" /* webpackChunkName: "component---src-pages-for-growers-shippers-internet-of-things-js" */),
  "component---src-pages-for-growers-shippers-js": () => import("./../src/pages/for-growers-shippers.js" /* webpackChunkName: "component---src-pages-for-growers-shippers-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-stock-js": () => import("./../src/pages/marketing-stock.js" /* webpackChunkName: "component---src-pages-marketing-stock-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-press-release-js": () => import("./../src/pages/press-release.js" /* webpackChunkName: "component---src-pages-press-release-js" */),
  "component---src-pages-traceability-js": () => import("./../src/pages/traceability.js" /* webpackChunkName: "component---src-pages-traceability-js" */),
  "component---src-pages-why-produceplan-js": () => import("./../src/pages/why-produceplan.js" /* webpackChunkName: "component---src-pages-why-produceplan-js" */)
}

